<template>
    <div>
        <ListingComponent ref="AllSearchedAudios"
                          class="bg-white"
                          :addNewButton="addNewButton"
                          :requestProvider="requestProvider"
                          :columnConfig="columnConfig">
        </ListingComponent>
        <b-modal v-model="isDeleteModal"
                 :no-close-on-backdrop="true"
                 centered title="Delete modal"
                 @cancel="closeDeleteModal"
                 @ok="removeItem">
            <form>
                <h3 class="text-black-50">Do you really want to delete</h3>
            </form>
        </b-modal>
    </div>
</template>

<script>
    import ListingComponent from '@/components/listing/ListingComponent'
    import moment from 'moment'

    export default {
        name: "AllSearchedAudiosPage",
        data () {
            return {
                id: null,
                isDeleteModal: false,
                columnConfig: [
                  {
                    columnCode: 'action',
                    columnName: 'Action',
                    value: (item) => {
                      return (
                          <b-dropdown variant="link" class="listing-action">
                          <b-dropdown-item onClick={() => this.showDeleteModal(item)}>Delete</b-dropdown-item>
                      </b-dropdown>
                    )
                    }
                  },
                  {
                    columnCode: 'audioLink',
                    columnName: 'Audio Link',
                    align: 'left',
                    value: item => {
                      return item.audioLink;
                    }
                  },
                  {
                    columnCode: 'musicName',
                    columnName: 'Music Name',
                    align: 'left',
                    value: item => {
                      return item.musicName;
                    }
                  },
                  {
                    columnCode: 'artist',
                    columnName: 'Artist',
                    align: 'left',
                    value: item => {
                      return item.artist;
                    }
                  },
                  {
                    columnCode: 'searchCount',
                    columnName: 'Search Count',
                    align: 'left',
                    value: item => {
                      return item.searchCount;
                    }
                  },
                  {
                    columnCode: 'searchDate',
                    columnName: 'Search Date',
                    align: 'left',
                    value: item => {
                      return item.searchDate ? moment(String(item.searchDate)).format('YYYY/MM/DD') : '';
                    }
                  }
                ]
            }
        },
        methods: {
            requestProvider (payload) {
                return this.axios.get('history/list', payload)
            },
          addNewButton () {
            return (
                <router-link class="btn btn-success mr-3" to="#"><i class="fas fa-plus-circle"></i></router-link>


          )
          },
          closeDeleteModal () {
                this.id = null
                this.$nextTick(() => {
                    this.isDeleteModal = false
                })
            },
            showDeleteModal (item) {
                if (!item.id) {
                    this.$toastr.error('Users not found')
                }
                this.id = item.id
                this.isDeleteModal = true
            },
            removeItem () {
                if (!this.id) {
                    return
                }
                this.axios.delete('history/item/', {params: {id: this.id}})
                    .then((result) => {
                        this.$refs.AllSearchedAudios.doRequest()
                        if (result.data && result.data.status === 'success') {
                            this.$toastr.success('User successfully deleted')
                        }
                        this.isDeleteModal = false
                    })
            }
        },
        components: {
            ListingComponent
        }
    }
</script>

<style scoped>

</style>